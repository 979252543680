// This file contains all the api paths used in the application

// BASE URL
export const baseURL = process.env.REACT_APP_BASE_URL;
// export const baseURL = 'http://localhost:8083/api';
// GET URL
export const getUserURL = `${baseURL}/user/by-ext-id/`;
export const getUserDetailUrl = `${baseURL}/user/`;
export const getIndustryList = `${baseURL}/enum/Industry`;
export const getCompanyNameList = `${baseURL}/enum/CompanySize`;
export const getDepartmentNameList = `${baseURL}/enum/DepartmentSize`;
export const getCorporateSpendList = `${baseURL}/enum/CorporateSpend`;
export const getCorporateRfpSpendList = `${baseURL}/enum/RfpSpend`;
export const getCountryList = `${baseURL}/country`;
export const getJurisdictionList = `${baseURL}/enum/Jurisdiction`;
export const getLanguageList = `${baseURL}/language`;
export const getTimeZonesList = `${baseURL}/enum/timeZones`;
export const getKeyAreasOfFocusList = `${baseURL}/enum/KeyAreasOfFocus`;
export const getRestructuringList = `${baseURL}/enum/RoleInRestructuringAndInsolvency`;
export const getScopeOfWorkList = `${baseURL}/scope-of-work`;
export const getCurrencyList = `${baseURL}/currency`;
export const getCompletionTimeWeeksList = `${baseURL}/enum/CompletionTimeWeeks`;
export const getCorporateSelectList = `${baseURL}/enum/CorporateStatus`;
export const getCorporateList = `${baseURL}/corporate`;
export const getSpecificCorporateList = `${baseURL}/corporate/by-lsp`;
export const getLegalserviceslist = `${baseURL}/legal-service`;
export const getLegalServiceSelectList = `${baseURL}/enum/LegalServiceStatus`;
export const getCorporatePreview = `${baseURL}/corporate/preview`;
export const getLegalServicePreview = `${baseURL}/auth/ls/get-details`;
export const getLsSignUpDetails = `${baseURL}/auth/ls/get-details`;
export const getCodeDetails = `${baseURL}/enum/code-by-type/LegalServiceStatus`;
export const getLSCodeDetails = `${baseURL}/enum/code-by-type/CorporateStatus`;
export const getLegalServiceProvider = `${baseURL}/legal-service`;
export const getLegalServiceProviderByCorporate = `${baseURL}/legal-service/by-corporate`;
export const getCorporateProvider = `${baseURL}/corporate`;
export const getCorporateChartList = `${baseURL}/corporate/analytics/chart`;
export const getLegalServicesChartList = `${baseURL}/legal-service/analytics/chart`;
export const getKeyTeamMember = `${baseURL}/corporate/key-team-member-by-lsp`;
export const getKeyTeamMemberByCorporate = `${baseURL}/corporate/key-team-member-by-lsp-corporate`;
export const getDynamicContentLSP = `${baseURL}/legal-service/preview`;
export const getDynamicCorporateContentLSP = `${baseURL}/corporate/preview`;
export const getKeyTeamMemberScoreCard = `${baseURL}/corporate/analytics/key-team-member-score-card`;
export const getKeyTeamMemberScoreCardWithKtm = `${baseURL}/corporate/analytics/key-team-member-score-card-corporate`;
export const getKeyTeamMemberScoreCardGlobal = `${baseURL}/corporate/analytics/key-team-member-score-card-global`;
export const getLSKeyTeamMember = `${baseURL}/legal-service/key-team-member/by-corporate`;
export const getSpecificLSKeyTeamMember = `${baseURL}/legal-service/key-team-member/by-corporate/by-lsp`;
export const getByRfpMembersUrl = `${baseURL}/legal-service/rfp-by-key-team-member`;
export const getLSKeyTeamMemberScoreCard = `${baseURL}/legal-service/analytics/key-team-member-score-card-global`;
export const getSpecificLSKeyTeamMemberScoreCard = `${baseURL}/legal-service/analytics/key-team-member-score-card-by-lsp`;
export const getLSRfpScoreCardUrl = `${baseURL}/legal-service/analytics/rfp-score-card`;
export const getAnalyticsScoreCard = `${baseURL}/corporate/analytics/score-card`;
export const getCorporateScoreCardWithoutKtm = `${baseURL}/corporate/analytics/score-card-corporate`;
export const getSpecificCorporateAnalyticsScoreCard = `${baseURL}/legal-service/analytics/score-card-by-lsp`;
export const getCorporateAnalyticsScoreCard = `${baseURL}/legal-service/analytics/score-card`;
export const getUserList = `${baseURL}/user/by-corporate-id`;
export const getLSUserList = `${baseURL}/user/by-legalservice-id`;
export const getFile = `${baseURL}/auth/ls/get-file`;
export const getProposalList = `${baseURL}/proposal`;
export const getProposalDetailList = `${baseURL}/rfp`;
export const getFinancial = `${baseURL}/payment/finance-list`;
export const getAllProposalDetails = `${baseURL}/proposal`;
export const getExpectedFeesDetails = `${baseURL}/enum/ExpectedFees`;
export const getProposalStatus = `${baseURL}/enum/ProposalStatus`;
export const getHowYouHereAboutUs = `${baseURL}/enum/HowYouHearAbout`;
export const getLegalServicesReview = `${baseURL}/legal-service-provider-review`;
export const getLegalServicesAnalyticReview = `${baseURL}/legal-service-provider-review/by-lsp-or-lead-partner`;
export const getProposalLeadPartnerTitle = `${baseURL}/enum/ProposalLeadPartnerTitle`;
export const getProposalLeadPartnerExperience = `${baseURL}/enum/ProposalLeadPartnerExperience`;
export const getProposalLeadPartnerRole = `${baseURL}/enum/ProposalLeadPartnerRole`;
export const getFileDownload = `${baseURL}/rfp/get-file`;
export const getPaymentList = `${baseURL}/payment`;
export const getPaymentDetailByIdUrl = `${baseURL}/payment/receipt-url`;
export const getRevisionListUrl = `${baseURL}/prompt-revision/list`;

// POST URL
export const postCuSignup = `${baseURL}/auth/cu-signup`;
export const postLsSignup = `${baseURL}/auth/ls/signup`;
export const postLsSignUp = `${baseURL}/auth/ls/signup`;
export const postLsNonAccreditedSignUp = `${baseURL}/auth/ls/signup-non-accredited`;
export const postLsInviteAccessCheck = `${baseURL}/auth/ls/check-signup-invite`;
export const postLsUpload = `${baseURL}/auth/ls/upload-file`;
export const postGaInvite = `${baseURL}/legal-service/send-invite`;
export const postAddUser = `${baseURL}/user`;
export const postLsUser = `${baseURL}/user`;
export const postLsAddMainContactPerson = `${baseURL}/legal-service/add-main-contact-person`;
export const postLsRemoveMainContactPerson = `${baseURL}/legal-service/remove-main-contact-person`;
export const postCuAddMainContactPerson = `${baseURL}/corporate/add-main-contact-person`;
export const postCuRemoveMainContactPerson = `${baseURL}/corporate/remove-main-contact-person`;
export const postCorporateApproveDecline = `${baseURL}/corporate/approve-or-decline`;
export const postRequestForMissingInfo = `${baseURL}/corporate/request-for-missing-info`;
export const postUploadLogo = `${baseURL}/auth/ls/upload-logo`;
export const postLegalServiceApproveDecline = `${baseURL}/legal-service/approve-or-decline`;
export const postLegalServiceRequestForMissingInfo = `${baseURL}/legal-service/request-for-missing-info`;
export const postRfpUpload = `${baseURL}/rfp/upload-file`;
export const postProposalUpload = `${baseURL}/proposal/upload-file`;
export const postProposalCloseUploadUrl = `${baseURL}/proposal/clone-file`;
export const postContactDetail = `${baseURL}/contactus`;
export const postLSPReview = `${baseURL}/legal-service-provider-review`;
export const postCorporateSupport = `${baseURL}/corporate/support`;
export const postLsSupport = `${baseURL}/legal-service/support`;
export const postLspAccess = `${baseURL}/proposal/assign`;
export const postRevisionPromptUrl = `${baseURL}/prompt-revision/add`;

//RFP POST URL

export const postRFP = `${baseURL}/rfp`;
export const postRfpWinner = `${baseURL}/proposal/score-card`;
export const postRfpWinnerPaymentUrl = `${baseURL}/payment`;
export const postCreateTemplateUrl = `${baseURL}/rfp-template-bank`;
export const postProposalTemplateUrl = `${baseURL}/proposal-template-bank`;
export const postProposalMasterTemplateUrl = `${baseURL}/master-proposal-template-bank`;
export const postRfpFileCloneUrl = `${baseURL}/rfp/clone-file`;
export const retryPaymentUrl = `${baseURL}/payment/manual-retry-payment`;
export const postCorporateUserGroupUrl = `${baseURL}/user-groups`;
export const postLspUserGroupUrl = `${baseURL}/user-groups`;
export const createSessionUrl = `${baseURL}/session/create`;
export const postAiPromptUrl = `${baseURL}/prompt/get-score`;
export const postAiFeedbackPromptUrl = `${baseURL}/prompt-feedback/add`;

//RFP GET URL

export const getRFPStatus = `${baseURL}/enum/code-by-type/RFPStatus`;
export const getBidders = `${baseURL}/legal-service/bidders`;
export const getRfpList = `${baseURL}/rfp`;
export const getRfpListAdmin = `${baseURL}/rfp/admin-rfp`;
export const getFavoriteBidders = `${baseURL}/favorite-bidders`;
export const getFavoriteBidderAddFile = `${baseURL}/favorite-bidders-files/add`; //Add
export const getFavoriteBidderListFile = `${baseURL}/favorite-bidders-files/list`; //list
export const getFavoriteBidderFile = `${baseURL}/favorite-bidders-files/get-file`; //get File
export const getFavoriteBidderRemoveFile = `${baseURL}/favorite-bidders-files/remove`; //remove file
export const getRfpListById = `${baseURL}/rfp/`;
export const getRFPListStatus = `${baseURL}/enum/RFPStatus`;
export const getPaymentInfo = `${baseURL}/user-payment-method`;
export const addPaymentInfo = `${baseURL}/user-payment-method`;
export const getRfpWinnerAndLoserUrl = `${baseURL}/proposal/winner-and-loser`;
export const getProposalByRfpIdUrl = `${baseURL}/proposal/by-rfp-id`;
export const getConfigByKeyUrl = `${baseURL}/config/by-key`;
export const getTemplateUrl = `${baseURL}/rfp-template-bank`;
export const getProposalStatusUrl = `${baseURL}/rfp`;
export const getProposalTemplateUrl = `${baseURL}/proposal-template-bank`;
export const getMasterTemplateDetailsUrl = `${baseURL}/master-proposal-template-bank/detail`;
export const getProposalMasterTemplateUrl = `${baseURL}/master-proposal-template-bank/list`;
export const getConfigByIdUrl = `${baseURL}/config/by-key`;
export const getCorporateUserGroupUrl = `${baseURL}/user-groups/by-corporate-id`;
export const getLegalServiceUserGroupUrl = `${baseURL}/user-groups/by-lsp-id`;
export const getCorporateUserGroupDetailUrl = `${baseURL}/user-groups`;
export const getLspUserGroupDetailUrl = `${baseURL}/user-groups`;
export const getCorporateUserGroupUserUrl = `${baseURL}/user/user-group/by-corporate-id`;
export const getLspUserGroupUserUrl = `${baseURL}/user/user-group/by-lsp-id`;
//RFP PUT URL

export const putRFP = `${baseURL}/rfp/`;
export const putRFPAbort = `${baseURL}/rfp/abort`;
export const putEditUser = `${baseURL}/user`;
export const putProposalExport = `${baseURL}/proposal/export-proposal`;
export const putPasswordChange = `${baseURL}/user/change-password`;
export const putCorporateProfileUpdate = `${baseURL}/user/update-by-corporate-id`;
export const putFavoriteBidderUpdate = `${baseURL}/favorite-bidders/add`;
export const putLSPProfileUpdate = `${baseURL}/user/update-by-legalservice-id`;
export const putProposalDetails = `${baseURL}/proposal`;
export const updateMasterTemplateUrl = `${baseURL}/master-proposal-template-bank`;
export const updateTemplateUrl = `${baseURL}/proposal-template-bank`;
export const putPreliminaryInfoDetails = `${baseURL}/proposal/preliminary-info`;
export const updatePaymentInfo = `${baseURL}/user-payment-method`;
export const putCorporateUserGroupUrl = `${baseURL}/user-groups/update`;
export const putLspUserGroupUrl = `${baseURL}/user-groups/update`;
export const addRemoveAdminCorporateUserUserGroupUrl = `${baseURL}/user-groups/add-or-remove-admin`;
export const addRemoveAdminLspUserUserGroupUrl = `${baseURL}/user-groups/add-or-remove-admin`;
export const putCorporateUserGroupUpdateStatusUrl = `${baseURL}/user-groups/change-status`;
export const putLspUserGroupUpdateStatusUrl = `${baseURL}/user-groups/change-status`;
export const putRevisionPromptUrl = `${baseURL}/prompt-revision/update`;

//RFP DELETE URL

export const deleteTemplateList = `${baseURL}/rfp-template-bank`;
export const deleteFavoriteBidderList = `${baseURL}/favorite-bidders/remove`;
export const deleteProposalTemplateList = `${baseURL}/proposal-template-bank`;
export const deleteCorporateUserGroupUrl = `${baseURL}/user-groups/delete`;
export const deleteLspUserGroupUrl = `${baseURL}/user-groups/delete`;
export const removeCorporateUserUserGroupUrl = `${baseURL}/user-groups/delete-member`;
export const removeLspUserUserGroupUrl = `${baseURL}/user-groups/delete-member`;
export const deleteSessionUrl = `${baseURL}/session/delete`;
export const deleteAllSessionUrl = `${baseURL}/session/delete-all-session`;
export const deleteUserUrl = `${baseURL}/user/delete-user-by-id`;

// file url

export const masterRfpInfo = {
  preliminaryInformation: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/preliminary-information.png',
  pricing: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/pricing.png',
  otherKey: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/other-key-info.png',
  sow: {
    arbitration: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/arbitration.png',
    commercial_contracts: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/commercial-contracts.png',
    competition: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/competition.png',
    corporate_ma: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/corporate-m-a.png',
    data_protection_privacy: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/data-protection-privacy.png',
    employment: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/employment.png',
    financing_capital_markets: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/financing-capital-markets.png',
    fund_formation: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/fund-formation.png',
    fund_investment: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/fund-investment.png',
    insolvency: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/insolvency.png',
    ip: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/ip.png',
    it: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/it.png',
    litigation: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/litigation.png',
    other: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/other.png',
    infrastructure_projects_financing: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/projects-financing.png',
    regulatory: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/regulatory.png',
    restructuring: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/restructuring.png',
    tax: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/tax.png'
  }
};

// export const masterRfpInfo = {
//   preliminaryInformation: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/preliminary-information.png',
//   pricing: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/pricing.png',
//   otherKey: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/other-key-info.png',
//   sow: {
//     arbitration: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/arbitration.png',
//     commercial-contracts: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/commercial-contracts.png',
//     competition: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/competition.png',
//     corporate-m-a: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/corporate-m-a.png',
//     data-protection-privacy: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/data-protection-privacy.png',
//     employment: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/employment.png',
//     financing-capital-markets: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/financing-capital-markets.png',
//     fund-formation: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/fund-formation.png',
//     fund-investment: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/fund-investment.png',
//     insolvency: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/insolvency.png',
//     ip: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/ip.png',
//     it: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/it.png',
//     litigation: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/litigation.png',
//     other: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/other.png',
//     projects-financing: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/projects-financing.png',
//     regulatory: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/regulatory.png',
//     restructuring: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/restructuring.png',
//     tax: 'https://zaven-all-public.s3.eu-west-2.amazonaws.com/rfp-forms/sow/tax.png'
//   }
// };
